@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "~plyr/dist/plyr.css"; */
@import "~video.js/dist/video-js.css";
@import "~video.js/dist/video-js.css";

html,
body {
  padding: 0;
  margin: 0;
  /* min-width: max-content; */
  height: auto;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: Amnesty;
  background-color: black;
  /* overscroll-behavior-y: none; */
}

/* normal / regular */
@font-face {
  font-family: "Amnesty";
  font-weight: 400;
  src: url(./assets/fonts/AmnestyTradeGothic.ttf);
}

/* medium */
@font-face {
  font-family: "Amnesty";
  font-weight: 500;
  src: url(./assets/fonts/AmnestyTradeGothic-Cn18.ttf);
}

/* bold */
@font-face {
  font-family: "Amnesty";
  font-weight: 700;
  src: url(./assets/fonts/AmnestyTradeGothic-Bd2.ttf);
}

#root {
  height: 100%;
}

.text-shadow-page1 {
  /* text-shadow: 0px 0px 8px rgba(0, 0, 0, 1); */
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.vjs-amnesty .vjs-big-play-button {
  /* background-color: rgba(255, 254, 3,0.7);
  color:;
  border-color:black */
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

/* .bg-reeeed{
  background-color: red;
} */